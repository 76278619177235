import {
  commonPropsForSSR,
  deleteSingleCookie,
  getfullUrl,
  isUpdateLocalJobCount,
  localJobCountUpdate,
} from '@components/common';
import LoginUpdated from '@components/loginUpdated';
import { CompaniesHiringCount } from '@components/shared.types';
import { getCompaniesHiringCount } from 'API/jobs';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useEffect } from 'react';

type IProps = {
  localization: any;
  config: any;
  isEmployerLogin: boolean;
  isEmployerLoginPage?: boolean;
  fromForgetPassword: boolean;
  deviceType: string;
  url: string;
  CompaniesHiringCount: CompaniesHiringCount;
};

export default function LoginPage(props: IProps) {
  const {
    localization,
    config,
    fromForgetPassword,
    isEmployerLogin,
    CompaniesHiringCount,
  } = props;

  useEffect(() => {
    if (window && window.LOGIN) {
      window.LOGIN.Accounts.logOutV2(true, false, '_session_id');
      deleteSingleCookie('_session_id');
    }
    if (isUpdateLocalJobCount()) {
      localJobCountUpdate(CompaniesHiringCount);
    }
  }, []);

  return (
    <>
      <Head>
        <title>
          Login to the best database of telecommuting and flexible jobs |
          FlexJobs
        </title>
        <meta
          name="description"
          content="Login to access the best hand-screened job database for telecommuting, freelance, part-time and flexible schedule jobs."
        />
        <meta
          name="keywords"
          content="telecommuting jobs, part-time jobs, telecommute jobs, telecommuting job, part-time job, freelance job, freelance jobs, flexible jobs, remote jobs"
        />
        <meta
          property="og:title"
          content="Login to the best database of telecommuting and flexible jobs | FlexJobs"
        />
        <meta
          property="og:description"
          content="Login to access the best hand-screened job database for telecommuting, freelance, part-time and flexible schedule jobs."
        />
        <meta
          property="og:image"
          content="https://www.flexjobs.com/images/logos/flexjobsopengraph.png"
        />
        <meta property="og:url" content={props.url} />
        <meta property="og:type" content="website" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#004F6D"></meta>
        <link rel="canonical" href={props.url} />
      </Head>
      <LoginUpdated
        localization={localization}
        config={config}
        fromForgetPassword={fromForgetPassword}
        deviceType={props.deviceType}
        isEmployerLogin={isEmployerLogin}
      />
    </>
  );
}

export const getServerSideProps: GetServerSideProps<IProps> = async ({
  query,
  req,
}) => {
  let isForgetPassword: boolean = false;
  if (query && query?.forgotPassword && query?.forgotPassword === 'true') {
    isForgetPassword = true;
  }

  const fullUrl = getfullUrl(req);

  const commonSSRProps = await commonPropsForSSR(req, 'login');

  if (commonSSRProps.isLoggedIn)
    return {
      redirect: {
        destination: '/members',
        permanent: false,
      },
    };

  let isEmployerLogin = false;
  const CompaniesHiringCount = await getCompaniesHiringCount();
  if (query && query.emp) {
    isEmployerLogin = true;
  }
  // Required in Layout to change header

  return {
    props: {
      isEmployerLogin: isEmployerLogin,
      isEmployerLoginPage: isEmployerLogin,
      localization: commonSSRProps.localization,
      config: commonSSRProps.config,
      headerSearch: true,
      fromForgetPassword: isForgetPassword,
      deviceType: commonSSRProps.deviceType,
      url: fullUrl,
      CompaniesHiringCount: CompaniesHiringCount || null,
    },
  };
};
